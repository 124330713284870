import React from "react";
import SEO from "./seo";

export const PageMeta = ({
    metaDescription,
    metaTitle,
    metaKeywords,
    robots,
    richSchema,
}) => {
    return (
        <SEO
            title={metaTitle}
            description={metaDescription}
            keywords={metaKeywords}
            metaRobots={robots}
            richSchema={richSchema}
        />
    );
};
